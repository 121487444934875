import request from '@/utils/request';

export async function list({ partnerId, params }) {
  return request({
    url: `partners/${partnerId}/bank-accounts`,
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `partners/${data.partnerId}/bank-accounts/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id, partnerId }) {
  return request({
    url: `partners/${partnerId}/bank-accounts/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: `/partners/${data.partnerId}/bank-accounts`,
    method: 'post',
    data,
  });
}

export function get({ id, partnerId }) {
  return request({
    url: `/partners/${partnerId}/bank-accounts/${id}`,
    method: 'get',
  });
}
