import alertsApi from './api';

export async function list(params) {
  return alertsApi({
    url: '',
    method: 'get',
    params,
  });
}

export function get(id) {
  return alertsApi({
    url: `/${id}`,
  });
}

export function update({ id, ...data }) {
  return alertsApi({
    url: `/${id}`,
    method: 'put',
    data,
  });
}

export function read(id) {
  return alertsApi({
    url: `/${id}/read`,
    method: 'post',
  });
}

export function del(id) {
  return alertsApi({
    url: `/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return alertsApi({
    url: '/',
    method: 'post',
    data,
  });
}
