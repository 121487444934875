import request from '@/utils/request';

export async function list(params) {
  return request({
    url: '/roomAssignments/requests',
    method: 'get',
    params,
  });
}

export async function approve({ id, ...data }) {
  return request({
    url: `/roomAssignments/requests/${id}/approve`,
    method: 'post',
    data,
  });
}

export async function reject({ id, ...data }) {
  return request({
    url: `/roomAssignments/requests/${id}/reject`,
    method: 'post',
    data,
  });
}
