import alertsApi from './api';

export function add({ id, files }) {
  const formData = new FormData();
  files.forEach((f) => {
    if (f.id === undefined) {
      formData.append('images', f.originFileObj || f);
    }
  });

  return alertsApi({
    url: `/${id}/images`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function deleteImage({ id, imageId }) {
  return alertsApi({
    url: `/${id}/images/${imageId}`,
    method: 'delete',
  });
}
