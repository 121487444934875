<template>
  <a-config-provider :locale="locale">
    <Header />
    <router-view v-slot="{ Component }">
      <transition mode="out-in" name="view-fade">
        <keep-alive :include="keepAliveInclude">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <FullScreenLoader />
  </a-config-provider>
</template>

<script>
import en from 'ant-design-vue/es/locale/en_US';
import ja from 'ant-design-vue/es/locale/ja_JP';
import Header from '@/components/Header';
import FullScreenLoader from '@/components/FullScreenLoader';

export default {
  name: 'App',
  components: {
    FullScreenLoader,
    Header,
  },
  data() {
    return {
      keepAliveInclude: ['home'],
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale === 'ja' ? ja : en;
    },
  },
  created() {
    document.documentElement.setAttribute('lang', this.$i18n.locale);
  },
};
</script>

<style></style>
