import request from '@/utils/request';

export async function list(params) {
  return request({
    url: 'expenses',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/expenses/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id, ...params }) {
  return request({
    url: `/expenses/${id}`,
    method: 'delete',
    params,
  });
}

export function add(data) {
  return request({
    url: '/expenses',
    method: 'post',
    data,
  });
}

export function get({ id, userId }) {
  return request({
    url: `/expenses/${id}`,
    method: 'get',
    params: { userId },
  });
}

export function uploadReceipt({ id, file }) {
  const formData = new FormData();
  formData.append('receipt', file.originFileObj || file);

  return request({
    url: `/expenses/${id}/receipt`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export async function requestApproval({ ids }) {
  return request({
    url: '/expenses/request-approval',
    method: 'post',
    data: { ids },
  });
}

export async function approve({ id }) {
  return request({
    url: `/expenses/${id}/approve`,
    method: 'put',
  });
}
