import alertsApi from './api';

export function list(alertId) {
  return alertsApi({
    url: `/${alertId}/comments`,
  });
}

export function update({ id, alertId, ...data }) {
  return alertsApi({
    url: `/${alertId}/comments/${id}`,
    method: 'put',
    data,
  });
}

export function deleteComment({ alertId, id }) {
  return alertsApi({
    url: `/${alertId}/comments/${id}`,
    method: 'delete',
  });
}

export function add({ id, ...data }) {
  return alertsApi({
    url: `/${id}/comments`,
    method: 'post',
    data,
  });
}

export function addCommentImage({ alertId, id, files }) {
  const formData = new FormData();
  files.forEach((f) => {
    if (f.id === undefined) {
      formData.append('images', f.originFileObj || f);
    }
  });

  return alertsApi({
    url: `/${alertId}/comments/${id}/images`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function deleteCommentImage({ alertId, id, imageId }) {
  return alertsApi({
    url: `/${alertId}/comments/${id}/images/${imageId}`,
    method: 'delete',
  });
}
