import request from '@/utils/request';

export async function list({ params }) {
  return request({
    url: '/calendar',
    method: 'get',
    params,
  });
}

export async function addBlockedDates({ roomId, data }) {
  return request({
    url: `/rooms/${roomId}/blocked-dates`,
    method: 'post',
    data,
  });
}

export async function deleteBlockedDates({ roomId, id }) {
  return request({
    url: `/rooms/${roomId}/blocked-dates/${id}`,
    method: 'delete',
  });
}

export async function editBlockedDates({ roomId, data }) {
  return request({
    url: `/rooms/${roomId}/blocked-dates`,
    method: 'put',
    data,
  });
}

export async function deleteBlockedDateMany({ roomId, ids }) {
  return request({
    url: `/rooms/${roomId}/blocked-dates`,
    method: 'delete',
    data: { ids },
  });
}

export async function addBlockedDatesToRooms({ data }) {
  return request({
    url: '/rooms/blocked-dates',
    method: 'post',
    data,
  });
}

export async function deleteBlockedDateManyRooms({ rooms, blockedDates }) {
  return request({
    url: '/rooms/blocked-dates',
    method: 'delete',
    data: { rooms, blockedDates },
  });
}
